<template>
  <div>
    <div class="erp-form__title" style="margin-left: 20px; font-size: 16px; margin-top: -10px">批量申请开票</div>
    <ErpList
      url="/malicrm/invoice/output/queryBatchInvoiceListWithPage"
      rowId="orderId"
      :columOptions="columnList"
      :noPaging="true"
      :selection="true"
      :customParams="queryFormData"
      ref="tableRef"
      id="BatchVoice"
      :maxHeight="660"
      @select-change="handleSelectionChange"
    >
      <template #right>
        <div class="erp-form__header header-bar__sticky" style="margin-bottom: 0px">
          <div></div>
          <div class="erp-form-bar" style="margin-right: 20px">
            <el-button size="small" type="primary" class="erp-primary__btn" @click="next">提交申请开票</el-button>
            <div class="erp-default__btn" @click="back">返回</div>
          </div>
        </div>
      </template>
    </ErpList>
    <div class="summary-bottom">
      <span>合计订单数：{{ selectionList.length }} </span>
      <span>合计开票金额：{{ totalSum }}</span>
    </div>
    <el-dialog v-model="elDialogShow" v-if="elDialogShow" :append-to-body="true" custom-class="invoice-batch-dialog" title="货物信息" width="790px">
      <goods-info :productList="recordVoList" :taxAmountDesc="taxAmountDesc" :invoiceVariety="invoiceVariety"></goods-info>
    </el-dialog>
  </div>
</template>
<script>
import { httpPost } from '@/api';
import { toRaw, getCurrentInstance, ref, computed } from 'vue';
import goodsInfo from './goodsInfo.vue';
import useListData from './useBatchListData';
import ErpList from '@/components/ErpList/ErpList.vue';
import { errorMessage, successMessage, _formatFloat, _thousandBitSeparator } from '@/utils/publicMethods';
export default {
  name: 'BatchVoice',
  props: {
    invoiceVariety: {
      type: [Number, String],
      default: '',
    },
  },
  components: { goodsInfo, ErpList },
  emits: ['success', 'cancel', 'edit'],
  setup(props, ctx) {
    const Instance = getCurrentInstance();
    const { columnList, tableRef } = useListData(Instance);
    const selectionList = ref([]);
    const recordVoList = ref([]);
    const productList = ref([]);
    // const productList = tableRef.value.listDatas;
    const taxAmountDesc = ref('');
    const totalOrder = ref(0);
    const totalAmount = ref(0);
    const elDialogShow = ref(false);
    const totalSum = computed(() => {
      const data = selectionList.value;
      let total = 0;
      if (data.length > 0) {
        total = data.reduce((total, item) => {
          const totalAmount = Number(item.totalAmount);
          return total + totalAmount;
        }, 0);

        total = _formatFloat(total, 2);
        total = _thousandBitSeparator(total);
      }
      return total;
    });
    const queryFormData = ref({
      pageNum: 1,
      pageSize: 100,
      invoiceVariety: props.invoiceVariety,
    });
    const validForm = () => {
      let result = true;
      const data = selectionList.value;
      const allField = data.every((v) => {
        // 发票品类为货物发票时必填项为购买方、纳税人识别号、地址/电话、开户行及账号

        if (v.invoiceType !== 3) {
          return v.buyerName && v.buyerTaxPayerNumber && v.buyerContactsInfo && v.buyerBankInfo;
        } else {
          return true;
        }
        // // 发票品类为服务发票时，必填项为本次开票金额、购买方、纳税人识别号、地址/电话、开户行及账号
        // if (Number(this.invoiceVariety) === 2) {
        //   return v.totalAmount;
        // }
      });
      if (allField === false) {
        errorMessage('请将必填项填写完整');
        return false;
      }
      // 货物信息里的货物不可为空
      const allGoods = data.every((v) => v.goodsInfoList.length > 0);
      if (!allGoods) {
        errorMessage('货物信息里的货物不可为空');
        return false;
      }
      console.log(data);
      let contractPrincipalName = data.map((v) => v.contractPrincipalName);
      contractPrincipalName = [...new Set(contractPrincipalName)];
      // 主体必须相同
      result = contractPrincipalName.length == 1;

      if (!result) {
        errorMessage('批量开票的签约主体必须相同');
      }
      return result;
    };
    // 提交表单
    const sumbitForm = () => {
      // 保存或编辑表单
      const params = { saveDtos: selectionList.value };
      httpPost('/malicrm/invoice/output/batchInvoice', params).then((res) => {
        if (res.code === 200) {
          successMessage('申请开票成功');
          ctx.emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
        } else {
          errorMessage(res.message);
        }
      });
    };
    // 下一步
    const next = () => {
      if (selectionList.value.length > 0) {
        if (validForm()) {
          sumbitForm();
        }
      } else {
        errorMessage('请选择要开票的订单');
      }
    };
    const openGoodsInfoDialog = (row) => {
      recordVoList.value = row.recordVoList;
      taxAmountDesc.value = row.taxAmountDesc;
      if (Number(props.invoiceVariety) === 2) {
        let taxAmount = row.totalAmount * row.taxRate * 0.01;
        taxAmount = _formatFloat(taxAmount, 2);
        taxAmountDesc.value = _thousandBitSeparator(taxAmount);
        if (!taxAmountDesc.value || taxAmountDesc.value === '0') {
          taxAmountDesc.value = '';
        }
        recordVoList.value[0].totalTaxAmountDesc = _thousandBitSeparator(row.totalAmount);
      }
      elDialogShow.value = true;
    };
    const updateRow = (row, index) => {
      const { totalAmount, buyerName, invoiceType, invoiceTypeDesc, buyerTaxPayerNumber, buyerContactsInfo, buyerBankInfo } = row;
      productList.value[index].buyerName = buyerName;
      productList.value[index].buyerTaxPayerNumber = buyerTaxPayerNumber;
      productList.value[index].buyerContactsInfo = buyerContactsInfo;
      productList.value[index].buyerBankInfo = buyerBankInfo;
      productList.value[index].invoiceType = invoiceType;
      productList.value[index].invoiceTypeDesc = invoiceTypeDesc;
      if (Number(props.invoiceVariety) === 2) {
        productList.value[index].totalAmount = totalAmount;
      }
    };
    const changeAmount = (e, name, index) => {
      let value = e.target.value;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, '-'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      value = value.replace('-', '$#$').replace(/-/g, '').replace('$#$', '-');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 100000000 || value <= -100000000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          if (value <= -100000000) {
            value = value.substr(0, 9);
          } else {
            value = value.substr(0, 8);
          }
        }
      }
      if (Number(value) === 0) {
        value = null;
      }
      productList.value[index][name] = value ? Number(value) : null;
      if (value) {
        productList.value[index].taxAmount = value * productList.value[index].taxRate * 0.01;
      }
    };
    const handleSelectionChange = (val) => {
      for (let i = 0; i < val.length; i++) {
        val[i].goodsInfoList = val[i].recordVoList.map((item) => {
          item.totalAmount = item.totalTaxAmount;
          return item;
        });
        val[i].invoiceNumber = null;
      }
      selectionList.value = toRaw(val);
    };
    // 返回
    const back = () => {
      ctx.emit('cancel', false);
    };
    // editVoice
    const editVoice = (row, index) => {
      productList.value = tableRef.value.listDatas;
      ctx.emit('edit', row, index);
    };
    return {
      columnList,
      tableRef,
      elDialogShow,
      selectionList,
      recordVoList,
      totalOrder,
      totalAmount,
      productList,
      totalSum,
      queryFormData,
      taxAmountDesc,
      next,
      updateRow,
      back,
      editVoice,
      openGoodsInfoDialog,
      handleSelectionChange,
      changeAmount,
    };
  },
};
</script>
<style lang="scss">
.product_table_style {
  margin-top: 24px;
}
.summary-bottom {
  margin-left: 16px;
  color: #303234;
  font-size: 14px;
  margin-top: 16px;
  span {
    margin-right: 24px;
  }
}
.goods-view {
  color: #2878ff;
  font-size: 14px;
  cursor: pointer;
}
</style>
