import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref, toRaw } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 166,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 206,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 206,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '发票品类',
      prop: 'invoiceVarietyDesc',
      minWidth: 86,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
    },
    {
      label: '开票类型',
      prop: 'invoiceTypeDesc',
      minWidth: 120,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
    },
    {
      label: '本次开票金额',
      prop: 'totalAmountDesc',
      minWidth: 130,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
      // component: defineComponent({
      //   template: `
      //   <div v-if="invoiceVariety == 1">
      //       {{ scope.row.totalAmountDesc }}
      //     </div>
      //     <el-input
      //       v-if="invoiceVariety == 2"
      //       :maxlength="11"
      //       v-model="scope.row.totalAmount"
      //       clearable
      //       placeholder=""
      //       class="mali-full__inputcom"
      //       @blur="changeAmount($event, 'totalAmount', scope.$index)"
      //     ></el-input>
      //   `,
      //   props: ['row'],
      //   setup() {
      //     const changeAmount = (row) => {
      //       Instance.setupState.changeAmount(toRaw(row));
      //     };

      //     return { changeAmount };
      //   },
      // }),
    },
    {
      label: '购买方',
      prop: 'buyerName',
      minWidth: 206,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '纳税人识别号',
      prop: 'buyerTaxPayerNumber',
      minWidth: 180,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
    },
    {
      label: '地址/电话',
      prop: 'buyerContactsInfo',
      minWidth: 236,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
    },
    {
      label: '开户行及账号',
      prop: 'buyerBankInfo',
      minWidth: 180,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
    },
    {
      label: '货物信息',
      prop: '',
      minWidth: 90,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
      component: defineComponent({
        template: `
        <span class="goods-view" @click.stop="openGoodsInfoDialog(row,col)" v-if="row.recordVoList && row.recordVoList.length > 0">查看</span>
        <span v-else>-</span>
        `,
        props: ['row', 'col', 'column'],
        setup() {
          const openGoodsInfoDialog = (row, col) => {
            if (col.label === '货物信息') {
              Instance.setupState.openGoodsInfoDialog(row);
            }
          };

          return { openGoodsInfoDialog };
        },
      }),
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 85,
      isFilter: false,
      isSort: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain @click.stop="editVoice(row,col,index)" size="mini">编辑</el-button>
        `,
        props: ['row', 'col', 'index'],
        setup() {
          const editVoice = (row, col, index) => {
            if (col.label === '操作') {
              Instance.setupState.editVoice(row, index);

            }
          };

          return { editVoice };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
