<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">申请开票</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="发票品类" prop="invoiceVariety">
                <el-select disabled v-model="formData.invoiceVariety" placeholder="请选择发票品类" class="mali-full__input">
                  <el-option v-for="item in Options.invoice_variety" :value="item.value" :label="item.label" :key="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税率" prop="taxRateDesc">
                <el-input :maxlength="12" v-model="formData.taxRateDesc" disabled placeholder class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票类型" prop="invoiceType">
                <el-select v-model="formData.invoiceType" placeholder="请选择开票类型" class="mali-full__input">
                  <el-option v-for="item in Options.invoice_output_type" :value="item.value" :label="item.label" :key="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本次开票金额" prop="totalAmountDesc">
                <el-input
                  :maxlength="11"
                  v-model="formData.totalAmountDesc"
                  clearable
                  disabled
                  placeholder="请输入本次开票金额"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="erp-form__header" style="margin-bottom: 22px">
          <div class="erp-form__title f16" style="margin-left: 16px">购买方</div>
        </div>
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item
                label="名称"
                prop="buyerName"
                :rules="
                  formData.invoiceType !== 3
                    ? [
                        {
                          required: true,
                          message: '请填写名称',
                          trigger: 'change',
                        },
                      ]
                    : null
                "
              >
                <el-input :maxlength="40" v-model="formData.buyerName" clearable placeholder="请输入名称" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="纳税人识别号"
                prop="buyerTaxPayerNumber"
                :rules="
                  formData.invoiceType !== 3
                    ? [
                        {
                          required: true,
                          message: '请填写纳税人识别号',
                          trigger: 'change',
                        },
                        {
                          len: 18,
                          message: '长度固定18个字符',
                          trigger: 'blur',
                        },
                      ]
                    : [
                        {
                          len: 18,
                          message: '长度固定18个字符',
                          trigger: 'blur',
                        },
                      ]
                "
              >
                <el-input
                  :maxlength="18"
                  v-model="formData.buyerTaxPayerNumber"
                  clearable
                  placeholder="请输入纳税人识别号"
                  class="mali-full__inputcom"
                  @blur="changeValidate($event, 'buyerTaxPayerNumber')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                prop="buyerContactsInfo"
                label="地址/电话"
                :rules="
                  formData.invoiceType !== 3
                    ? [
                        {
                          required: true,
                          message: '请填写地址/电话',
                          trigger: 'blur',
                        },
                      ]
                    : null
                "
              >
                <el-input :maxlength="100" v-model="formData.buyerContactsInfo" placeholder="请输入地址/电话" type="textarea" class="inner-88"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="开户行及账号"
                prop="buyerBankInfo"
                :rules="
                  formData.invoiceType !== 3
                    ? [
                        {
                          required: true,
                          message: '请填写开户行及账号',
                          trigger: 'change',
                        },
                      ]
                    : null
                "
              >
                <el-input :maxlength="100" v-model="formData.buyerBankInfo" placeholder="请输入开户行及账号" type="textarea" class="inner-88"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { store } from '@/store';
export default {
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    rowIndex: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      rules: {},
      formData: {
        arAmount: null,
        id: null,
        invoiceNumber: null,
        invoiceOutputNo: null,
        invoiceType: null,
        invoiceTypeDesc: null,
        invoiceVariety: null,
        orderId: null,
        taxAmount: null,
        taxRate: null,
        taxRateDesc: null,
        totalAmount: null,
        totalAmountDesc: null,
        buyerBankInfo: null,
        buyerContactsInfo: null,
        buyerName: null,
        buyerTaxPayerNumber: null,
        goodsInfoList: [],
      },
      Options: {
        invoice_variety: store.state.dict.options.invoice_variety,

        invoice_output_type: store.state.dict.options.invoice_output_type,
      },
    };
  },
  created() {
    this.formData = Object.assign({}, this.formData, this.row);
    this.formData.goodsInfoList = this.row.recordVoList.map((item) => {
      item.totalAmount = item.totalTaxAmount;
      return item;
    });
    if (!this.formData.totalAmountDesc && this.formData.totalAmount) {
      let amount = this.formData.totalAmount;
      amount = this._formatFloat(amount, 2);
      amount = this._thousandBitSeparator(amount);
      this.formData.totalAmountDesc = amount;
    }
    if (this.formData.totalAmountDesc === '' || this.formData.totalAmountDesc === null) {
      this.formData.totalAmountDesc = '-';
    }
  },
  methods: {
    submit() {
      // 提交操作
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      // 提交表单
      this.successTip('保存成功', 3000);
      const invoiceTypeObj = this.Options.invoice_output_type.find((item) => {
        return item.value === this.formData.invoiceType;
      });
      this.formData.invoiceTypeDesc = invoiceTypeObj.label;
      const params = Object.assign({}, this.formData);
      this.$emit('editBack', params, this.rowIndex);
    },
    back() {
      this.$emit('cancel');
    },
    changeValidate(e, name) {
      let value = e.target.value;
      value = value.replace(/[^0-9a-zA-Z]/g, ''); // 清除“数字”和“.”以外的字符
      this.formData[name] = value;
    },
  },
};
</script>
